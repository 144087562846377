// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//https://balramchavan.medium.com/configure-and-build-angular-application-for-different-environments-7e94a3c0af23
export const environment = {
  production: false,
  environmentName: 'DEV environment',
  partnerDashboardAddress:'https://dev-partner.madetogo.de',
  //partnerDashboardAddress:'http://localhost:8100',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
