import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    private currentHash = null;
    private currentVersion = null;

    constructor(private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,) { }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        //do the version check right at beginning
        this.checkVersion(url);

        setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    public getCurrentVersion(): string {
        if(this.currentVersion==null){
            this.currentVersion = localStorage.getItem("current_version");
        }
        return this.currentVersion;
    }

    public getCurrenHash() {
        if(this.currentHash==null){
        this.currentHash = localStorage.getItem("current_hash");
        }
        return this.currentHash;
    }

    private setCurrentHash(currentHash){
        this.currentHash = currentHash;
        localStorage.setItem("current_hash",currentHash);
    }

    private setCurrentVersion(currentVersion){
        this.currentVersion = currentVersion;
        localStorage.setItem("current_version",currentVersion);
    }


    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        console.log("checking for new version..");
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            //.first()
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    let currentHash = this.getCurrenHash();
                    console.log("response hash: " + hash);
                    console.log("current hash: " + currentHash);
                    if(currentHash!=null){
                        const hashChanged = this.hasHashChanged(currentHash, hash);
                        // If new version, do something
                        if (hashChanged) {
                            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                            // for an example: location.reload();
                            console.log("a new version is available");
                            this.forceReload(hash, response.version);
                        } else {
                            console.log("the version has not changed");
                        }
                    }else{
                        console.log("opening the app the first time, reload required");
                        
                        this.forceReload(hash, response.version);
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    //https://stackoverflow.com/questions/51435349/my-pwa-web-app-keep-showing-old-version-after-a-new-release-on-safari-but-works
    forceReload = (hash, version) =>
     
        // navigator.serviceWorker
        //     .getRegistrations()
        //     .then((registrations) =>
        //         Promise.all(registrations.map((r) => r.unregister())),
        //     )
        //     .then(() =>{
        //    })
        {
            //service workers not supported in private mode browsers or http, normal reload is enough
            this.setCurrentHash(hash);
            this.setCurrentVersion(version);
            window.location.reload(true)
        }
    
    //TODO: delete cache of service worker https://stackoverflow.com/questions/45467842/how-to-clear-cache-of-service-worker

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        return currentHash !== newHash;
    }
}